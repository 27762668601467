import React from 'react'
import { graphql, Link } from 'gatsby'
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import {
  getUrl,
  getPrice
} from 'utils/product'

const Product = styled.div`
  text-align: center;
  margin: 0 0 ${p => p.noMargin ? 0 : '40px'} 0;

  ${p => p.theme.media.minWidth('tablet')} {
    margin: 0 0 ${p => p.noMargin ? 0 : '50px'} 0;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  margin: 0 0 12px 0;
  overflow: hidden;
  background-color: ${p => p.theme.colors.ltGreen}; 

  .gatsby-image-wrapper [data-main-image] {
    transition: none;
    opacity: 1;
  }
  
  .gatsby-image-wrapper:nth-child(1) {
    display: block;
  }

  .gatsby-image-wrapper:nth-child(2) {
    ${p => p.theme.mixins.fill('absolute')}
    opacity: 0;
    transform: scale(1.02);
    transition: 
      opacity 600ms cubic-bezier(0.25, 0.1, 0.25, 1), 
      transform 600ms cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  ${Product}:hover & {
    .gatsby-image-wrapper:nth-child(2) {
      opacity: 1;
      transform: scale(1);
    }
  }
`

const Vendor = styled.div`
  ${p => p.theme.typography.tNormalImpact}
`

const Title = styled.div`
  ${p => p.theme.typography.tNormal}
  margin: 0 0 .3em 0;
`

const PriceWrapper = styled.div`
  ${p => p.theme.typography.tNormal}
`

const Price = styled.span`
  display: inline-block;
`

const CompareAtPrice = styled.span`
  display: inline-block;
  margin: 0 10px 0 0;
  text-decoration: line-through;
`

const Card = ( props ) => {
  const variant = props.variants[0]

  const renderImage = ( index ) => {
    const data = props.images[index]
    if ( !data ) {
      return index === 0 ? (
        <StaticImage
            src='../../images/product-card-placeholder.jpg'
            alt='' />
      ) : null
    }
    
    const image = getImage(data)

    
    return (
      <GatsbyImage image={image} alt='' />
    )
  }   
  
  const renderCompareAtPrice = () => {
    if ( !variant.compareAtPrice ) return

    const pcompareAtPrice = parseFloat(variant.compareAtPrice)
    const pprice = parseFloat(variant.price)

    if ( pprice >= pcompareAtPrice ) return

    return (
      <CompareAtPrice>{getPrice(variant.compareAtPrice)}</CompareAtPrice>
    )
  }
  
  return (   
    <Product noMargin={props.noMargin}>
      <Link to={getUrl(props.handle)} onClick={() => sessionStorage.setItem('scrollPosition', window.pageYOffset)} >
        <ImageWrapper>
          {renderImage(0)}
          {renderImage(1)}
        </ImageWrapper>
        <Vendor>{props.vendor}</Vendor>
        <Title>{props.title}</Title>
        <PriceWrapper>
          {renderCompareAtPrice()}
          <Price>{getPrice(variant.price)}</Price>
        </PriceWrapper>
      </Link>
    </Product>
  )
}

export const query = graphql`
  fragment Product on ShopifyProduct {
    storefrontId
    title
    handle
    productType
    vendor     
    tags
    images {
      id
      gatsbyImageData(width: 750)
    }    
    collections {
      title
      handle
    }
    options {
      name
      values        
    }
    variants {
      storefrontId
      price
      compareAtPrice      
    }
  }
`

export default Card


